import React, { useEffect, useState, useCallback } from 'react';
import { create } from 'zustand';
import { Layout, Select, Table, Button, Modal, Form, Input, message, Space, Grid, Row, Col, Drawer } from 'antd';
import { FilterOutlined } from '@ant-design/icons';
import Papa from 'papaparse';

const { Header, Content, Footer } = Layout;
const { useBreakpoint } = Grid;

// Zustand store for managing select options
const useSelectStore = create((set) => ({
    items: [],
    city: [],
    manufacturer: [],
    addItem: (newItem) =>
        set((state) => ({
            items: [...state.items, newItem].sort((a, b) => a.localeCompare(b)),
        })),
    addCity: (newCity) =>
        set((state) => ({
            city: [...state.city, newCity].sort((a, b) => a.localeCompare(b)),
        })),
    addManufacturer: (newManufacturer) =>
        set((state) => ({
            manufacturer: [...state.manufacturer, newManufacturer].sort((a, b) => a.localeCompare(b)),
        })),
}));

// Helper function to extract unique values
const getUniqueValues = (data, key) => [...new Set(data.map((item) => item[key]))];

// Filter data based on selected filters
const filterData = (data, filters) => {
    const { name, city, manufacturer } = filters;
    return data.filter((item) => {
        const matchesName = !name || item['Название'] === name;
        const matchesCity = !city || item['Точка отгрузки'] === city;
        const matchesManufacturer = !manufacturer || item['Производитель'] === manufacturer;
        return matchesName && matchesCity && matchesManufacturer;
    });
};

// Generate columns for the Table
const generateColumns = (handleButtonClick) => [
    { title: 'Название', dataIndex: 'Название' },
    { title: 'Производитель', dataIndex: 'Производитель' },
    { title: 'Характеристики', dataIndex: 'Характеристики' },
    { title: 'Фасовка', dataIndex: 'Фасовка' },
    { title: 'Точка отгрузки', dataIndex: 'Точка отгрузки' },
    { title: 'Цена за тонну (руб.)', dataIndex: 'Цена за тонну (руб.)' },
    {
        title: 'Действия',
        key: 'actions',
        render: (_, record) => (
            <Button type="primary" onClick={() => handleButtonClick(record)}>
                Оставить заявку
            </Button>
        ),
    },
];

const App = () => {
    const screens = useBreakpoint();

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [filters, setFilters] = useState({ name: null, city: null, manufacturer: null });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const { addItem, addCity, addManufacturer, city, items, manufacturer } = useSelectStore();

    // Fetch data from Google Sheets using Papa Parse
    useEffect(() => {
        Papa.parse(
            'https://docs.google.com/spreadsheets/d/e/2PACX-1vQOKeCAjQ0LQfoURU7AZbA_GEsTlA-XaomHzjXfUIVT5fBtsNXtE-PG7DcJxgh8VZ8NEsv9nma4xUwe/pub?output=csv',
            {
                download: true,
                header: true,
                complete: (results) => {
                    const parsedData = results.data;
                    setData(parsedData);
                    setFilteredData(parsedData);

                    const uniqueNames = getUniqueValues(parsedData, 'Название');
                    const uniqueLocations = getUniqueValues(parsedData, 'Точка отгрузки');
                    const uniqueManufacturers = getUniqueValues(parsedData, 'Производитель');

                    uniqueNames.forEach(addItem);
                    uniqueLocations.forEach(addCity);
                    uniqueManufacturers.forEach(addManufacturer);
                },
            }
        );
    }, [addItem, addCity, addManufacturer]);

    // Update filtered data based on filters
    useEffect(() => {
        setFilteredData(filterData(data, filters));
    }, [filters, data]);

    // Handle filter change
    const handleFilterChange = useCallback((value, type) => {
        setFilters((prev) => ({ ...prev, [type]: value }));
    }, []);

    // Show modal with form when button is clicked
    const handleButtonClick = (record) => {
        setSelectedProduct(record);
        setIsModalVisible(true);
    };

    // Handle form submission and send message to Telegram
    const handleFormSubmit = async (values) => {
        const { phoneOrEmail, quantity } = values;
        const messageText = `
      Заявка на товар:
      Название: ${selectedProduct['Название']}
      Производитель: ${selectedProduct['Производитель']}
      Точка отгрузки: ${selectedProduct['Точка отгрузки']}
      Фасовка: ${selectedProduct['Фасовка']}
      Цена за тонну: ${selectedProduct['Цена за тонну (руб.)']}
      
      Данные клиента:
      Телефон/Email: ${phoneOrEmail}
      Количество: ${quantity}
    `;

        try {
            await fetch(`https://api.telegram.org/bot7169505156:AAHTsmsQ6prtslqINxoxeSi87DmFAo1mxNk/sendMessage`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    chat_id: '-4501541933',
                    text: messageText,
                }),
            });

            message.success('Заявка успешно отправлена!');
            setIsModalVisible(false);
        } catch (error) {
            message.error('Ошибка при отправке заявки.');
            console.error('Ошибка при отправке сообщения в Telegram:', error);
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header
                style={{
                    background: '#fff',
                    padding: '10px 20px',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: screens.md ? 'space-between' : 'flex-start',
                }}
            >
                {screens.md ? (
                    <Row gutter={[16, 16]} style={{ width: '100%' }}>
                        <Col xs={24} sm={8} md={8} lg={6}>
                            <Select
                                placeholder="Название"
                                allowClear
                                style={{ width: '100%' }}
                                options={items.map((item) => ({ value: item, label: item }))}
                                onChange={(value) => handleFilterChange(value, 'name')}
                            />
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6}>
                            <Select
                                placeholder="Производитель"
                                allowClear
                                style={{ width: '100%' }}
                                options={manufacturer.map((item) => ({ value: item, label: item }))}
                                onChange={(value) => handleFilterChange(value, 'manufacturer')}
                            />
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6}>
                            <Select
                                placeholder="Точка отгрузки"
                                allowClear
                                style={{ width: '100%' }}
                                options={city.map((item) => ({ value: item, label: item }))}
                                onChange={(value) => handleFilterChange(value, 'city')}
                            />
                        </Col>
                    </Row>
                ) : (
                    <Button
                        type="primary"
                        icon={<FilterOutlined />}
                        onClick={() => setDrawerVisible(true)}
                    >
                        Фильтры
                    </Button>
                )}
            </Header>

            <Content style={{ padding: '20px', marginTop: screens.md ? 0 : '20px' }}>
                <div
                    style={{
                        background: '#fff',
                        minHeight: 280,
                        padding: 24,
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        overflow: 'auto',
                    }}
                >
                    <Table
                        columns={generateColumns(handleButtonClick)}
                        dataSource={filteredData}
                        pagination={{ pageSize: 10 }}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </Content>

            <Footer style={{ textAlign: 'center', padding: '10px' }}>
                Bla Bla Bla Contacts
            </Footer>

            <Drawer
                title="Фильтры"
                placement="right"
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Select
                        placeholder="Название"
                        allowClear
                        style={{ width: '100%' }}
                        options={items.map((item) => ({ value: item, label: item }))}
                        onChange={(value) => handleFilterChange(value, 'name')}
                    />
                    <Select
                        placeholder="Производитель"
                        allowClear
                        style={{ width: '100%' }}
                        options={manufacturer.map((item) => ({ value: item, label: item }))}
                        onChange={(value) => handleFilterChange(value, 'manufacturer')}
                    />
                    <Select
                        placeholder="Точка отгрузки"
                        allowClear
                        style={{ width: '100%' }}
                        options={city.map((item) => ({ value: item, label: item }))}
                        onChange={(value) => handleFilterChange(value, 'city')}
                    />
                </Space>
            </Drawer>

            <Modal
                title="Оставить заявку"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <Form layout="vertical" onFinish={handleFormSubmit}>
                    <Form.Item
                        name="phoneOrEmail"
                        label="Телефон или Email"
                        rules={[{ required: true, message: 'Пожалуйста, введите телефон или email' }]}
                    >
                        <Input placeholder="Введите номер телефона или email" />
                    </Form.Item>
                    <Form.Item
                        name="quantity"
                        label="Количество"
                        rules={[{ required: true, message: 'Пожалуйста, введите количество' }]}
                    >
                        <Input type="number" placeholder="Введите количество" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Отправить
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
    );
};

export default App;
